.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}

.headerLink {
  text-decoration: none;
  cursor: pointer;
  color: #42454A;
  font-size: 14px;
}

.logoutButton {
  cursor: pointer;
  color: #42454A;
  font-size: 14px;
  border: none;
  outline: none;
  background: transparent;
}

/*.container {*/
/*  text-align: center;*/
/*}*/

.title {
  text-align: center;
}

.top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top ul {
  list-style: none;
}

.maintenance {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.personsList {
  display: flex;
  border: 1px solid #42454A;
  border-bottom: transparent;
}

.personsList ul {
  padding: 0;
  margin-bottom: 0;
}

.personsList li {
  list-style-type: none;
  padding: 5px 10px;
  border-bottom:1px solid #42454A;
}

.members {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.membersWebcam {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inputName {
  width: 400px;
  margin: 5px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.faceImage {
  width: auto;
  height: 150px;
}

/*ドラッグ&ドロップ*/
.dndArea {
  margin: 5px;
  color: gray;
  padding: 8px;
  border: 2px solid gray;
  width: 200px;
  height: 150px;
}

.dndAreaContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone {
  width: 195px;
  height: 145px;
  border: 1px dotted gray;
}

.imageContainer {
  width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.pos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.posWebCam {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.selfPos {
  width: 200px;
  height: 150px;
  border: solid 1px #FA7349;
  border-radius: 10px;
}

.faceImage {
  margin: 5px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button {
  padding: 8px;
  margin: 5px;
  cursor: pointer;
}

select {
  cursor: pointer;
  margin: 5px;
  padding: 3px;
}

.identificationImage {
  width: auto;
  height: 100px;
  margin: 5px;
  padding: 8px;
}

/* Loading */
.loading {
  color: #FA7349;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  /*-webkit-transform: translateZ(0);*/
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    /*-webkit-transform: rotate(0deg);*/
    transform: rotate(0deg);
  }
  100% {
    /*-webkit-transform: rotate(360deg);*/
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    /*-webkit-transform: rotate(0deg);*/
    transform: rotate(0deg);
  }
  100% {
    /*-webkit-transform: rotate(360deg);*/
    transform: rotate(360deg);
  }
}
